import React from "react"
import Link from 'gatsby-link';
import {Container, Row, Col} from "reactstrap";
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
      <div className="error404 d-flex align-items-center justify-content-center">
          <Container>
              <Row>
                  <Col md="12">
                      <h2 className="title color-gold text-center">Oooups! Wskazana strona nie istnieje!</h2>
                      <p className="text-center">Wskazana przez Ciebie strona nie istnieje. Widocznie podany adres URL jest wadliwy.</p>
                  </Col>
              </Row>
              <Row>
                  <Col md="3" className="ml-auto">
                      <Link to="/#apartments" className="btn btn-sea d-flex justify-content-center font-weight-bold">Szukam oferty</Link>
                  </Col>
                  <Col md="3">
                      <Link to="/cennik" className="btn btn-gold d-flex justify-content-center font-weight-bold">Szukam cennika</Link>
                  </Col>
                  <Col md="3" className="mr-auto">
                      <Link to="/#contact" className="btn btn-sea d-flex justify-content-center font-weight-bold">Szukam kontaktu</Link>
                  </Col>
              </Row>
          </Container>
      </div>
  </Layout>
)

export default NotFoundPage
